<template>
  <b-card>
    <b-overlay :show="show" no-wrap fixed z-index="9999">
      <template v-slot:overlay>
        <div class="d-flex align-items-center">
          <b-spinner small type="grow" variant="dark"></b-spinner>
          <b-spinner type="grow" variant="dark"></b-spinner>
          <b-spinner small type="grow" variant="dark"></b-spinner>
        </div>
      </template>
    </b-overlay>
    <div v-if="page.id">
      <form @submit.prevent="itemEdited" id="edit-item">
        <div class="row">
          <div class="col-md-6">
            <b-form-group>
              <label for="title"> عنوان <i class="fas fa-asterisk text-danger" style="font-size: 0.5rem;"></i></label>
              <b-form-input id="title" name="title" v-model="page.title" :disabled="disabled"></b-form-input>
            </b-form-group>
          </div>
          <div class="col-md-6">
            <b-form-group label="Slug" label-for="slug">
              <b-form-input id="slug" name="slug" v-model="page.slug" :disabled="disabled"></b-form-input>
            </b-form-group>
          </div>
        </div>
        <b-form-group>
          <label for="body"> متن <i class="fas fa-asterisk text-danger" style="font-size: 0.5rem;"></i></label>
          <b-form-textarea id="body" rows="5" v-model="page.body" :disabled="disabled"></b-form-textarea>
        </b-form-group>

        <div class="form-group">
          <label class="custom-switch">
            <input type="checkbox" name="status" class="custom-switch-input" v-model="page.status" :disabled="disabled">
            <span class="custom-switch-indicator"></span>
            <span class="custom-switch-description">وضعیت</span>
          </label>
        </div>
        <button class="btn btn-success" type="submit" :disabled="disabled">ویرایش</button>
        <input type="hidden" name="_method" value="PUT">
        <input type="hidden" name="meta_desc" :value="page.meta_desc">
        <input type="hidden" name="meta_tag" :value="page.meta_tag">
      </form>
    </div>
  </b-card>
</template>
<script>
import mixin from '../mixins/mixins'

  export default {
    mixins: [mixin],
    data() {
      return {
        url: '/api/admin/page',
        page: {},
        title: 'ویرایش صفحه',
      }
    },
    computed: {
      id() {
        return this.$route.params.page
      }
    },
    methods: {
      itemEdited() {
        this.disabled = true
        const body = window.CKEDITOR.instances.body.getData()
        let form = document.getElementById('edit-item')
        let formData = new FormData(form)
        formData.append('body', body)
        this.$axios.post(this.$root.baseUrl + this.url + `/${this.page.id}`, formData)
          .then(response => {
            this.$root.success_notification('صفحه با موفقیت ویرایش شد')
            this.$router.push('/admin/page')
          })
          .catch(error => {
            this.$root.error_notification(error)
          })
          .finally(() => {
            this.disabled = false
          })
      },
    },
    mounted() {
      if (this.id) {
        this.$axios.get(this.$root.baseUrl + '/api/admin/page/' + this.id)
        .then(response => {
           this.page = response.data.data
           this.loadCkeditor()
            this.show = false
        })
      }
    },
    created() {

    }
  }
</script>